(function (window, document, $, undefined) {
    var laravel = {
        init: function() {
            this.registerEvents();
        },

        registerEvents: function() {
            $('body').on('click', 'a[data-method]', this.handleMethod);
        },

        handleMethod: function(event) {
            var link = $(this),
                method = link.data('method').toUpperCase(),
                form;

            // Process only if method is PUT, PATCH or DELETE
            if ($.inArray(method, ['PUT', 'PATCH', 'DELETE']) === -1) {
                return;
            }

            event.preventDefault();

            // Action confirmation
            if (link.data('confirm')) {
                if (!laravel.confirmAction(link)) {
                    return false;
                }
            }

            form = laravel.createForm(link);
            form.submit();
        },

        confirmAction: function(link) {
            return confirm(link.data('confirm'));
        },

        createForm: function(link) {
            var form = $('<form>', {
                    method: 'POST',
                    action: link.attr('href')
                }),
                token = $('<input>', {
                    type: 'hidden',
                    name: '_token',
                    value: window.csrfToken
                }),
                methodInput = $('<input>', {
                    type: 'hidden',
                    name: '_method',
                    value: link.data('method')
                });

            return form.append(token, methodInput).appendTo('body');
        }
    };

    laravel.init();

})(window, document, jQuery);